import React from 'react'
import TextTruncate from 'react-text-truncate'

// Data
import data from '../content/Suppliers-Content.json'

// Elements
import { Par } from '@theme'
import { Layout } from '@layouts'
import { Artical } from '@sections'
import {
	BtnLink,
	BtnLinkWrapper,
	ContactCard,
	CompanyCard,
	CardWrapper,
} from '@components'

const IndexPage = (props) => (
	<Layout props={props}>
		<Artical
			title="Welcome"
			par={`Pumptec is a leading supplier of pumps, flow meters and blending equipment. We pride ourselves in having one of the most comprehensive ranges of products in Southern Africa. We are agents for Piusi, Roper Pumps, Sero Pump systems, Desmi, Liquid Controls, Midland Pumps and many more. Our head office is based in Tunney, Johannesburg.`}
		>
			<BtnLinkWrapper marginTop="6vh">
				<BtnLink title="View more about us" linkTo="/about" />
			</BtnLinkWrapper>
		</Artical>
		<Artical
			theme="primary"
			title="Suppliers"
			par={`
					We supply a wide range of quality products, from local and international suppliers.
				`}
		>
			<CardWrapper marginTop="2em" colRowCount={3}>
				{data.suppliers.map((supplier) => (
					<CompanyCard
						key={supplier.name}
						company={supplier.company}
						intro={
							<TextTruncate
								line={4}
								element="span"
								truncateText="…"
								text={supplier.intro}
							/>
						}
						link={`/products/${supplier.name}`}
					/>
				))}
			</CardWrapper>
			<BtnLinkWrapper marginTop="6vh" borderColor="primary">
				<BtnLink title="View more suppliers" linkTo="/products" />
			</BtnLinkWrapper>
		</Artical>
		<Artical>
			<CardWrapper>
				<ContactCard
					label="Telephone"
					info={<Par>+27 (0) 11 822 1621</Par>}
					icon="telOutline"
					tel="+27118221621"
				/>
				<ContactCard
					label="Email"
					info={<Par>sales@pumptec.co.za</Par>}
					icon="mail"
					email="sales@pumptec.co.za"
				/>
				<ContactCard
					label="Location"
					info={<Par>Google Maps</Par>}
					icon="location"
					mapLocation
				/>
			</CardWrapper>
		</Artical>
	</Layout>
)

export default IndexPage
